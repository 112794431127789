import { FormlySelectOption } from "@common/interfaces/formly";
import { InterfaceName, InterfaceNameValue } from "@common/interfaces/issueTypeInterface";

export namespace ObjectHelpers {

  export const purgeUndefined = (obj: Object) => {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  };
  /**
   * Check if there is at least one property that is not null, undefined or empty string ''
   * Useful to check forms
   */
  export const hasOnlyEmptyValues = (obj: Object) => {
    for (const key in obj) {
      if (
        obj[key] !== undefined &&
        obj[key] !== "" &&
        obj[key] !== null &&
        !(Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        return false;
      }
    }
    return true;
  };

  export const hasProps = (obj: object) => {
    return Object.keys(obj).length > 0;
  };

  export function enumMembersName(): FormlySelectOption[] {
    const result: FormlySelectOption[] = [];
    for (const value in Object.keys(InterfaceName)) {
      if (typeof InterfaceName[value] !== "string") {
        continue;
      }
      result.push({ id: value, name: InterfaceName[Number(value)] });
    }
    return result;
  }

  /**
   * @deprecated
   * @param items 
   * @returns 
   */
  export function interfacesToOptions(
    items: InterfaceNameValue[]
  ): FormlySelectOption[] {
    return items.map((item) => {
      const interfaceName = Object.entries(InterfaceName).find(([key, value]) => value === item);
      return {
        id: interfaceName[1].toString(),
        name: interfaceName[0].toString().replace(/_/g, " "),
      }
    });
  }

  function replace(
    str: string,
    toReplace: string,
    replaceWith: string
  ): string {
    return str.split(toReplace).join(replaceWith);
  }

  export function hasNestedObject(object: Object): boolean {
    let hasNested: boolean = false;
    for (let value of Object.values(object)) {
      if (
        value !== null &&
        typeof value === "object" &&
        Array.isArray(value) === false
      ) {
        hasNested = true;
      }
    }
    return hasNested;
  }

  export function arrEquals(arr1: any[], arr2: any[]): boolean {
    if ((arr1 && !arr2) || (!arr1 && arr2) || (!arr1 && !arr2)) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    if (arr1.length === 0 && arr2.length === 0) {
      return true;
    }
    return arr1.every((el) =>
      arr2.some((el2) => {
        if (typeof el !== typeof el2) {
          return false;
        }
        if (Array.isArray(el) && Array.isArray(el2)) {
          return arrEquals(el, el2);
        }
        if (typeof el === "object") {
          return objEquals(el, el2);
        }
        //el === el2
      })
    );
  }

  export function objEquals(obj1: {}, obj2: {}): boolean {
    if ((obj1 && !obj2) || (!obj1 && obj2) || (!obj1 && !obj2)) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (typeof obj2[key] !== typeof obj1[key]) {
        return false;
      }
      if (Array.isArray(obj2[key]) && Array.isArray(obj1[key])) {
        return arrEquals(obj2[key], obj1[key]);
      }
      if (typeof obj2[key] === "object") {
        return objEquals(obj2[key], obj1[key]);
      }
      if (obj2[key] !== obj1[key]) {
        return false;
      }
    }
  }

  type Groupable = { [key: string | number]: any };
  /**
   * Raggruppa un array di oggetti in base al valore di una proprietà. Se stringForNull è una stringa non nulla (vuota va bene -> caso per cui è stata
   * aggiunto il parametro stringForNull), allora raggruppa le entità T che hanno quella proprietà nulla o undefined sul campo rinominato da stringForNull.
   */
  export function groupBy<T extends Groupable>(array: T[], key: keyof T, stringForNullish?: string): { [key: string | number]: T[] } {
    //TODO: stringForNull dovrà diventare parametro obbligatorio? Obbliga così a gestire sempre i casi con key a valore nullish (ci sta).
    return array.reduce<{ [key: string | number]: T[] }>((acc, curr) => {

      // Possibili valori per curr[key]: string (anche vuota), number e, se stringForNullish è una stringa, null, undefined 
      if ((typeof curr[key] !== 'string' && typeof curr[key] !== 'number' && (typeof stringForNullish !== 'string' || !!curr[key])) || typeof curr[key] === 'boolean') {
        throw new Error("Property value is neither a string, or a number or a nullish value.");  // Entro in errore anche se sono oggetto (non null) e array.
      }

      const value = typeof curr[key] === 'string' ? curr[key].trim() : curr[key];
      if (typeof stringForNullish === 'string' && (value === null || value === undefined)) {
        // Per entrare nell'if, stringForNullish deve essere stringa (anche vuota), e value deve essere nullish (ricorda che stringa vuota non conta)
        (acc[stringForNullish] = acc[stringForNullish] || []).push(curr);
        return acc;
      }

      (acc[value] = acc[value] || []).push(curr);
      return acc;
    }, {});
  }

  export function encode(d: Date, salt: number, base = 62): string {
    //           |       10|                       36|                       62|             78|    
    const abc = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!?([{_-.:,;@#}])"
    const symbols = abc.split("");
    if (base <= 1) base = 2;
    if (base > symbols.length) base = symbols.length;
    //console.log(abc.substring(0,base));
    let tvms = d.getTime() * 1000 + salt;
    let rv = "";
    while (tvms >= 1) {
      const idx = tvms - (base * Math.floor(tvms / base));
      rv = symbols[idx] + rv;
      tvms = Math.floor(tvms / base);
    }

    return rv;
  }

  /** Funzione che cerca un campo specifico all'interno di un oggetto (anche nidificato, quindi la ricerca è su tutti i livelli/profondità) e restituisce
   * il valore associato della prima istanza trovata. Si possono usare anche le regular expression. La ricerca è BFS (Breadth-First Search) */
  export function findPropInObjWithRegExp(obj: {}, propName: string | RegExp): { found: boolean, value: any } {
    const props: [string, any][] = Object.entries(obj);

    let condition: boolean;
    // Caching della funzione all'inizio del metodo, così non vado ogni volta a vedere se propName è stringa o meno
    const func = (key: string, val: any): { found: boolean, value: any } => {
      if (typeof propName === 'string') {
        condition = key === propName;
      } else {
        condition = !!key.match(propName);
      };
      if (condition) {
        return { found: true, value: val };
        // Per distinguere quando trovo il valore null (che sarebbe quindi { found: true, value: null }), dal semplice null qui sotto.
      };
      return null;
    };

    for (const [key, val] of props) {
      // Caso base
      const res = func(key, val);
      if (res !== null) {  // Cioè quando res è un oggetto del tipo { value: any }
        return res;
      };

      // Il campo key non era uguale/matchava con propName, quindi posso analizzare il val della key per vedere se è un oggetto o array, in caso vado a controllare dentro di esso
      if (!val || typeof val !== 'object') {
        // Caso in cui val non è né un oggetto né array (e né null, devo controllare perchè null è di tipo object) quindi è un valore semplice come una stringa, numero, o booleano
        continue;
      };

      if (Array.isArray(val)) {  // Caso in cui val è un array
        for (const elm of val) {
          if (!elm || typeof elm !== 'object') {
            // Come if di qualche riga sopra
            continue;
          }
          props.push(...Object.entries(elm));
        }
      } else {  // Ultimo caso rimasto: val è un oggetto 
        props.push(...Object.entries(val));
      }
    };

    return null;
  };

  /** Object.keys() tipizzata (Object.values già lo è) */
  export function objectKeys<Obj>(obj: Obj): (keyof Obj)[] {
    return Object.keys(obj) as (keyof Obj)[];
  }

  export function convertStringToObject(stringObj: string) {
    if (!stringObj) {
      return null;
    }
    return JSON.parse(stringObj);
  }

}

