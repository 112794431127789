import { Inject, Injectable } from "@angular/core";
import { IMetaInterfaces } from "@common/interfaces/metaInterfaces";
import { Topics } from "@common/interfaces/topics";
import { ID } from '@common/interfaces/id';
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { MetaInterfaceServiceProvider } from "./metaInterfaces.provider.service";
import { STORAGE_INFO_KEY } from "@ep-om/app.module";
import { LocalStorageService } from "@ep-om/project/service/localstorage.service";

export interface MetaInterfacesState extends EntityState<IMetaInterfaces, ID> { }

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: Topics.INTERFACES })
export class MetaInterfacesStore extends EntityStore<MetaInterfacesState> {

  constructor(
    private localStorage: LocalStorageService,
    @Inject(STORAGE_INFO_KEY) private storageInfoKey: string,
  ) {
    super([]);
  }

  reset(): void {
    this.localStorage.remove(this.storageInfoKey);
    super.reset();
  }
}
