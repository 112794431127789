import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode, { JwtPayload } from "jwt-decode";
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, filter, pairwise, retryWhen, switchMap, tap } from 'rxjs/operators';
import { AuthStore, clientId } from './auth.store';

import { Router } from '@angular/router';
import { IUser } from '@common/interfaces/user';
import { resetStores } from '@datorama/akita';
import { AppService } from '@ep-om/app.service';
import { WebpushNotificationService } from '@ep-om/core/services/wpn.service';
import { genericRetryStrategy } from '@ep-om/utils/genericRetryStrategy';
import { logger } from '@ep-om/utils/logger';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from 'src/environments/environment';
import { AuthErrorMessage } from '../config/const';
import { AuthQuery } from './auth.query';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _logout$ = new Subject<void>();
  public loggingOut$ = this._logout$.asObservable();
  private _login$ = new Subject<void>();
  public loggingIn$ = this._login$.asObservable();
  private apiUrl = environment.apiUrl;
  private redirectUrl: string;
  public mainTab$: Observable<boolean>;
  clientId: string = clientId();  

  constructor(
    private http: HttpClient,
    private nzns: NzNotificationService,
    private authStore: AuthStore,
    public authQuery: AuthQuery,
    private wpns: WebpushNotificationService,
    private router: Router,
    private appService: AppService,
  ) {

    this.initialize();

  }

  

  async initialize() {
    this.appService.mainTab$.pipe(
      tap((main) => console.log(main ? '[AUTH] - I Am the main tab' : '[AUTH] - I am NOT the main tab')),
      switchMap(
        (isMain) => {
          if (!isMain) {
            return of(null);
          }
          return this.authQuery.refreshToken$.pipe(
            tap((token) => console.log('[AUTH] empty token ?')),
            filter(refresh => !!refresh), //what if refresh token never emits??
            switchMap(refresh => {
              if (this.checkTokenExpiration(refresh)) {
                console.log('[AUTH] Refresh token is expired')
                throw Error("Expired Refresh Token")
              }
              const expires = this.getExpirationFromToken(this.authStore.getValue().accessToken);
              let timeout = expires.getTime() - new Date().getTime() - (10 * 1000);
              console.log('[AUTH] token expires in', timeout / 1000, "secs");
              timeout = timeout <= 0 ? 100 : timeout;
              return of(refresh).pipe(delay(timeout));
            }),
            catchError((err, _) => {
              return of(err);
            }),
          )
        }
      ),
      filter(refresh => !!refresh),
      tap(() => console.log('[AUTH] refreshing now')),
      switchMap(refreshToken => this.refresh(refreshToken).pipe(
        catchError((err, _) => {
          return of(err);
        }),
      )),
      // takeUntil(this.authQuery.refreshToken$.pipe(filter(token => !token), distinctUntilChanged())),
      // repeatWhen(() => this.authQuery.refreshToken$.pipe(filter(token => !!token), distinctUntilChanged())),
    ).subscribe({
      next: async (response: HttpErrorResponse | Error | { accessToken: string, refreshToken: string }) => {
        if (response instanceof HttpErrorResponse || response instanceof Error) {
          if (response.message === 'not logged in') {
            return;
          }
          console.log('[AUTH] - ops during refresh', response)
          // this.nzns.create(
          //   'error',
          //   'Autenticazione fallita, è necessario ripetere il login.',
          //   ''
          // );
          return await this.logout();
        }
        console.log('[AUTH] - new token arrived')
        this.authStore.update(state => ({
          ...state,
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          userAgent: window.navigator.userAgent
        }))
      },
      complete() {
        console.log('[AUTH] - completed')
      },
    });

    this.authQuery.loggedIn$.pipe(
      pairwise(),
      tap(async ([beforeLoggedIn, afterLoggedIn]) => {
        if (beforeLoggedIn === true && afterLoggedIn === false) {
          await this.router.navigate(['/login'])
        }
      })
    ).subscribe();
  }

  isLoggedIn() {
    return !!this.authQuery.getValue().accessToken;
  }

  setRedirectUlr(url: string) {
    this.redirectUrl = url;
  }

  refresh(token): Observable<HttpRequest<any>> {
    if (!this.isLoggedIn()) {
      return throwError(new Error('not logged in'))
    }
    return this.http
      .post(`${this.apiUrl}/api/auth/refresh`, { refreshToken: token, clientId: this.authStore.getValue().clientId, userAgent: window.navigator.userAgent })
      .pipe(
        retryWhen<HttpRequest<any>>(genericRetryStrategy({
          maxRetryAttempts: Infinity,
          scalingDuration: 3000,
          excludedStatusCodes: [401],
        })),
      )
  }

  setUserIdFromToken(token: string) {
    const payload = jwt_decode(token) as any;
    this.authStore.update({ userId: payload.userId })
  }

  getExpirationFromToken(token: string): Date {
    const payload = jwt_decode(token) as JwtPayload;
    if (payload.exp)
      return new Date(payload.exp * 1000);
    else
      return new Date(0)
  }

  checkTokenExpiration(token: string, date = new Date()): boolean {
    const expirationDate = this.getExpirationFromToken(token);

    if (expirationDate.valueOf() < date.valueOf())
      return true

    return false;
  }

  async forgotPassword(email: string) {

    try {
      await this.http.post(`${this.apiUrl}/api/auth/forgotpassword`, { email }).toPromise();
      this.nzns.create('success', 'Operazione effettuata.', 'Controllare la posta ...');
    }
    catch (err) {
      this.nzns.create('error', 'Operazione non riuscita.', err.message);
    }

  }

  login({ username = '', password = '' }) {
    this.authStore.setLoading(true);
    this.http
      .post(`${this.apiUrl}/api/auth/login`, { username, password, ...this.authStore.getValue() })
      //.pipe(this.httpPipe)
      .subscribe({
        next: (response: any) => {
          logger.log('Auth Response', response)
          this.authStore.update((state) => ({
            ...state,
            accessToken: response.accessToken,
            refreshToken: response.refreshToken
          }));
          this.setUserIdFromToken(this.authStore.getValue().refreshToken);
        },
        complete: async () => {
          this.nzns.create(
            'success',
            'Login effettuato.',
            ''
          );
          this._login$.next();
          if (this.redirectUrl) {
            console.log('redirecting to url');
            await this.router.navigateByUrl('/' + this.redirectUrl);
            this.redirectUrl = null;
            return;
          }
          await this.router.navigate(['/']);
        },
        error: (body) => {
          logger.log(body)
          if (body.status === 503) {
            this.appService.setMaintenance(true);
            this.router.navigate(['maintenance']);
          }
          this.checkAuthError(body.error.message, username)
        }
      });
  }

  async impersonate(username = ''): Promise<any> {
    this.authStore.setLoading(true);
    try {
      const rv = await this.http.post(`${this.apiUrl}/api/auth/impersonate`, { username, ...this.authStore.getValue() }).toPromise();
      return rv;
    } catch (error) {
      if (error.status === 401) {
        this.nzns.create(
          'error',
          'Impersonificazione negata',
          'Non si dispone del ruolo necessario'
        );
      }
      else {
        console.log(error);
        this.nzns.create(
          'error',
          'Impersonificazione negata',
          error.message
        );
      }
      return null;
    }
  }

  async setTokenData(accessToken: string, refreshToken: string) {
    console.log(accessToken, refreshToken);
    this.authStore.setLoading(true);
    this.authStore.update((state) => ({
      ...state,
      accessToken: accessToken,
      refreshToken: refreshToken
    }));
    this.setUserIdFromToken(this.authStore.getValue().refreshToken);
    this.authStore.setLoading(false);
    this.nzns.create(
      'success',
      'Utenza impersonata correttamente',
      ''
    );
    this._login$.next();
    await this.router.navigate(['/']);
  }

  checkAuthError(error: string, username?: string) {
    switch (error) {
      case AuthErrorMessage.Username: {
        const isEmail = username.includes('@')
        this.nzns.create(
          'error',
          `${isEmail ? 'Email' : 'Username'} non esistente.`,
          ''
        );
        break;
      }
      case AuthErrorMessage.Password: {
        this.nzns.create(
          'error',
          'Password sbagliata.',
          ''
        );
        break;
      }
      default: {
        this.nzns.create(
          'error',
          'Login fallito.',
          ''
        );
      }
    }
  }

  async register({ email = '', password = '', name = '' }) {
    try {
      await this.http.post(`${this.apiUrl}/api/auth/register`, { email, name, password }).toPromise();
      this.nzns.create('success', 'Operazione effettuata.', 'Controllare la posta ...');
      this.router.navigate(['login']);
    } catch (e) {
      const errorMessage = e.error?.message?.length > 0 ? e.error.message.map(m => ` • ${m}`).join('\n') : e.message;
      this.nzns.create('error', 'Operazione non riuscita.', errorMessage);
    }

  }


  async logoutForImpersonate() {
    try { await this.wpns.clean(); } catch (e) { }
    resetStores()
    this._logout$.next();
    
  }

  async logout() {
    try { await this.wpns.clean(); } catch (e) { }
    const clientId = this.authStore.getValue().clientId;
    const accessToken = this.authStore.getValue().accessToken;
    resetStores()
    this._logout$.next();
    try {
      await this.http
      .post(`${this.apiUrl}/api/auth/logout`,
        { clientId },
        { headers: { authorization: `Bearer ${accessToken}` } }
      ).toPromise()    
    } catch (e) {
      console.error('[AUTH SERVICE] - error during logout:', e);
    }
  }

  updateUser(user: IUser) {
    this.authStore.update(state => ({
      ...state,
      user
    }));
  }

}


